import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BaseUrlService } from '../../services/base-url.service';
import { Observable } from 'rxjs';
import type { ICompetition, ICompetitionBase, ICompetitionUser } from '@archery-scoring/models/competition.model';
import { CrudServiceInterface } from '@owain/store-features/models/crud-service.model';
import type { ITargetBase, ITargetPost } from '@archery-scoring/models/target.model';

@Injectable({
  providedIn: 'root',
})
export class CompetitionService implements CrudServiceInterface<ICompetition> {
  private readonly http = inject(HttpClient);
  private readonly baseUrlService: BaseUrlService = inject(BaseUrlService);

  private urlCompetitions = `${this.baseUrlService.getBaseUrl()}/api/competitions`;
  private urlTargets = `${this.baseUrlService.getBaseUrl()}/api/targets`;
  private urlScores = `${this.baseUrlService.getBaseUrl()}/api/targets`;

  getEntities(): Observable<ICompetition[]> {
    return this.http.get<ICompetition[]>(`${this.urlCompetitions}`);
  }

  getEntity(id: number): Observable<ICompetition> {
    return this.http.get<ICompetition>(`${this.urlCompetitions}/${id}`);
  }

  addEntity(competition: ICompetition): Observable<ICompetition> {
    const { postOrganisationId, ...data } = competition;
    return this.http.post<ICompetition>(`${this.urlCompetitions}/organization/${postOrganisationId}`, data);
  }

  updateEntity(competition: ICompetition): Observable<ICompetition> {
    const { postOrganisationId, ...data } = competition;
    return this.http.put<ICompetition>(
      `${this.urlCompetitions}/organization/${postOrganisationId}/${competition.id}`,
      data
    );
  }

  deleteEntity(competition: ICompetition): Observable<ICompetition> {
    return this.http.delete<ICompetition>(`${this.urlCompetitions}/${competition.id}`);
  }

  toggleCompetition(id: number): Observable<ICompetitionBase> {
    return this.http.post<ICompetitionBase>(`${this.urlCompetitions}/toggle/${id}`, {});
  }

  addOrUpdateTargetEntities(targets: ITargetPost[]): Observable<ITargetBase[]> {
    return this.http.post<ITargetBase[]>(this.urlTargets, targets);
  }

  getScores(competitionId: number): Observable<ICompetitionUser[]> {
    return this.http.get<ICompetitionUser[]>(`${this.urlCompetitions}/scores/${competitionId}`);
  }
}
