import { patchState, signalStore, type, withHooks, withMethods } from '@ngrx/signals';
import { setEntity, withEntities } from '@ngrx/signals/entities';
import { UserStore } from '../user/user.store';
import { ApplicationRef, effect, inject, isDevMode, PLATFORM_ID, untracked } from '@angular/core';
import type { ICompetition, ICompetitionBase } from '@archery-scoring/models/competition.model';
import { CompetitionService } from './competition.service';
import { isPlatformBrowser, Location } from '@angular/common';
import { withCallState } from '@owain/store-features/features/call-state/call-state.feature';
import { withDataService } from '@owain/store-features/features/data-service/data-service-feature';
import { NotificationType } from '@owain/notifier/lib/models/notifications.model';
import { withSideEffects } from '@owain/store-features/features/side-effects/side-effects.feature';
import { withEntitiesLocalPagination } from '@owain/store-features/features/entities/pagination/entities-local-pagination';
import { onEvent, withEventHandler } from '@owain/store-features/features/event-handler/event-handler';
import { createEvent, props } from '@owain/store-features/features/event-handler/event-handler.util';
import { withDevtools } from '@owain/store-features/features/debugging/devtools';
import type { ITargetBase, ITargetPost } from '@archery-scoring/models/target.model';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { pipe, switchMap } from 'rxjs';
import { tapResponse } from '@ngrx/operators';
import type { IMiscHttpError } from '@archery-scoring/models/misc.model';
import { ToasterService } from '@owain/notifier/lib/services/toaster.service';
import { TranslocoService } from '@jsverse/transloco';
import { WINDOW } from '@owain/tokens/window.provider';
import { filter, takeWhile, tap } from 'rxjs/operators';

export const AdminCompetitionsStore = signalStore(
  { providedIn: 'root' },
  // withLogger('AdminCompetitionsStore'),
  // withIndexedDB('admin-store-state', ['callstate']),
  withDevtools('Archery Scoring', 'Admin Competitions'),
  withCallState({
    collection: 'competition',
  }),
  withEntities({
    entity: type<ICompetition>(),
    collection: 'competition',
  }),
  withEntitiesLocalPagination({
    entity: type<ICompetition>(),
    collection: 'competition',
    pageSize: 5,
  }),
  // withEntitiesSort({
  //   entity: type<ICompetition>(),
  //   collection: 'competition',
  //   defaultSort: { field: 'id', direction: 'desc' },
  // }),
  withDataService({
    loggerKey: 'AdminCompetitionsStore',
    key: 'admin-competitions',
    dataServiceType: CompetitionService,
    collection: 'competition',
  }),
  withEventHandler(store => {
    const sideEffectEvent = createEvent(`admin-competition.sideEffect`, props<{ key: string; status: string }>());

    return [
      onEvent(sideEffectEvent, ({ key, status }) => {
        if (key === 'add' || key === 'get' || key === 'update' || key === 'delete') {
          // @ts-ignore
          store.sortCompetitionsEntities();
        }
      }),
    ];
  }),
  withSideEffects({
    key: 'admin-competitions',
    add: {
      success: {
        type: NotificationType.SUCCESS,
        message: 'admin.competitioncreated',

        redirect: 'back',
      },
      failure: {
        type: NotificationType.ERROR,
        message: '500error',
      },
    },
    update: {
      success: {
        type: NotificationType.SUCCESS,
        message: 'admin.competitionedited',

        redirect: 'back',
      },
      failure: {
        type: NotificationType.ERROR,
        message: '500error',
      },
    },
    delete: {
      success: {
        type: NotificationType.SUCCESS,
        message: 'admin.competitiondeleted',

        castEvent: 'ui:close-modal',
      },
      failure: {
        type: NotificationType.ERROR,
        message: '500error',
      },
    },
  }),
  withHooks({
    onInit(store) {
      const platformId: Object = inject(PLATFORM_ID);
      const ngWindow: Window = inject(WINDOW);
      const applicationRef: ApplicationRef = inject(ApplicationRef);
      const userStore: InstanceType<typeof UserStore> = inject(UserStore);

      const idleCallbackSupportedW = (window: Window | null): boolean =>
        typeof window !== 'undefined' ? !!(window as any).requestIdleCallback : false;

      const idleCallbackSupported: boolean = idleCallbackSupportedW(ngWindow);
      let subscriptionDone: boolean = false;

      effect(() => {
        const authenticated = userStore.isAuthenticated();
        const admin = userStore.isAdmin();

        untracked(() => {
          if (authenticated && admin) {
            if (isPlatformBrowser(platformId)) {
              if (!store.competitionsLoaded()) {
                subscriptionDone = false;

                applicationRef.isStable
                  .pipe(
                    takeWhile(() => !subscriptionDone),
                    filter(isStable => isStable),
                    tap(() => {
                      subscriptionDone = true;

                      if (idleCallbackSupported) {
                        ngWindow.requestIdleCallback(() => {
                          store.getCompetitions();

                          if (isDevMode()) {
                            console.log('%c 🗃️ Prefetching admin competitions', 'background: #fff; color: #607D8B;');
                          }
                        });
                      } else {
                        store.getCompetitions();

                        if (isDevMode()) {
                          console.log('%c 🗃️ Prefetching admin competitions', 'background: #fff; color: #607D8B;');
                        }
                      }
                    })
                  )
                  .subscribe();
              }
            }
          } else {
            store.resetCompetitions();
          }
        });
      });
    },
  }),
  withMethods(store => {
    const competitionsService: CompetitionService = inject(CompetitionService);
    const toastService: ToasterService = inject(ToasterService);
    const translocoService: TranslocoService = inject(TranslocoService);
    const location: Location = inject(Location);

    return {
      addOrUpdateTarget: rxMethod<{ competitionId: number; targets: ITargetPost[] }>(
        pipe(
          switchMap((data: { competitionId: number; targets: ITargetPost[] }) => {
            patchState(store, { competitionsCallState: 'loading' });

            return competitionsService.addOrUpdateTargetEntities(data.targets).pipe(
              tapResponse({
                next: (updatedTarget: ITargetBase[]): void => {
                  const competition: ICompetition | undefined = store
                    .competitionEntities()
                    .find((competition: ICompetition) => competition.id === data.competitionId);

                  if (!competition) {
                    return;
                  }

                  for (const key in updatedTarget) {
                    const indexedItem: ITargetBase = updatedTarget[key];

                    const targets: ITargetBase[] | undefined = competition.targets;

                    if (!targets || targets.length == 0) {
                      competition.targets = [indexedItem];
                    } else {
                      competition.targets = [
                        ...targets.filter(
                          (target: ITargetBase) =>
                            !(
                              target.targetnumber === indexedItem.targetnumber &&
                              target.subtargetnumber === indexedItem.subtargetnumber
                            )
                        ),
                        indexedItem,
                      ];
                    }

                    patchState(store, setEntity(competition, { collection: 'competition' }));
                  }

                  toastService.notification({
                    alertType: NotificationType.SUCCESS,
                    message: translocoService.translate('admin.targetupdated'),
                  });

                  location.back();
                },
                error: (err: IMiscHttpError) => {
                  if (isDevMode()) {
                    console.error('[competition]', err);
                  }
                },
                finalize: () => {
                  patchState(store, { competitionsCallState: 'loaded' });
                },
              })
            );
          })
        )
      ),

      toggleOpen: rxMethod<number>(
        pipe(
          switchMap((competitionId: number) => {
            patchState(store, { competitionsCallState: 'loading' });

            return competitionsService.toggleCompetition(competitionId).pipe(
              tapResponse({
                next: (_: ICompetitionBase): void => {
                  const competition: ICompetition | undefined = store
                    .competitionEntities()
                    .find((competition: ICompetition) => competition.id === competitionId);

                  if (!competition) {
                    return;
                  }

                  competition.open = !competition.open;

                  patchState(store, setEntity(competition, { collection: 'competition' }));
                },
                error: (err: IMiscHttpError) => {
                  if (isDevMode()) {
                    console.error('[competition]', err);
                  }
                },
                finalize: () => {
                  patchState(store, { competitionsCallState: 'loaded' });
                },
              })
            );
          })
        )
      ),
    };
  })
);
